import {
  INTRODUCING_CHARACTER_JOB_TYPE_LIST,
  LOCATION_SELECT_FIELDS_JOB_TYPE_LIST,
} from 'constants/vertical-lp';
import type { VerticalPageJobType } from 'modules/job-types/types';

import { isUrlVerticalPage } from 'utils/url';

type JobTypeDisplayOptions = { shouldUseSEOName: boolean };
export const getJobTypeDisplayName = (
  jobType: VerticalPageJobType,
  { shouldUseSEOName }: JobTypeDisplayOptions = { shouldUseSEOName: false }
) =>
  shouldUseSEOName && jobType.translations.seoName
    ? jobType.translations.seoName
    : jobType.translations.requestType;

export const JOB_TYPE_ID_DEFAULT = 'unknown-jobTypeId';

export const JOB_TYPE_CATEGORIES_DEFAULT = ['unknown-c1', 'unknown-c2', 'unknown-c3'];

export const getIsHomeRequestFormEntry = (jobType: VerticalPageJobType) =>
  jobType.categories.includes('home') &&
  jobType.forms[0] &&
  jobType.forms[0].name === 'propertyType';

export const getIsLearningRequestFormEntry = (jobType: VerticalPageJobType) =>
  jobType.categories.includes('learning') && jobType.forms[0] && jobType.forms[0].name === 'age';

export const getIsBusinessRequestFormEntry = (jobType: VerticalPageJobType) =>
  jobType.categories.includes('business') &&
  jobType.forms[0] &&
  jobType.forms[0].name === 'corporateindividual';

export const getIsSimpleQuestionRequestFormEntry = (jobType: VerticalPageJobType) => {
  const simpleQuestionRequestIdArray = [
    'math-teacher',
    'private-tutor',
    'tennis-instructor',
    'drumming-lessons',
    'electone-instructor',
    'guitar-teacher',
    'karaoke-singing-instructor',
    'piano-teacher',
    'ukulele-instructor',
    'violin-instructor',
    'vocal-instructor',
    'carinspectionspecialist',
    'snow-shoveling',
    'computerrepair',
    'groceryshopper',
    'radiorepairservice',
  ];

  return simpleQuestionRequestIdArray.includes(jobType.id);
};

export const getIsSimpleQuestionRequestFormEntryType2 = (jobType: VerticalPageJobType) => {
  const simpleQuestionRequestType2IdArray = [
    'handyman',
    'sparekeyproduction',
    'personal-chef',
    'dog-therapist',
    'catering',
    'kimono-dressing-master',
    'manicurist',
    'makeup-artist',
    'furniture-upholstery-repair',
    'kimonocleaner',
    'photographer',
    'autorepair',
    'refrigeratorrepair',
    'kimonotailoring',
    'carremodeling',
    'pet-sitter',
    'tv-mounting',
    'hair-stylist',
    'furniturepainting',
    'mover',
    'automotiveinteriorspecialist',
    'ikea-nitori-furniture-assembly',
    'pre-owned-condominium-renovator',
    'pet-trimmer',
    'pettrainer',
    'lifecoaching',
    'washing-machine-cleaner',
    'event-florist',
    'beautyadvisor',
    'food-truck',
    'computer-setup-support',
    'motorcyclerepair',
    'civil-engineer',
    'photographer-baby-photos',
    'photographer-headshots',
    'pianist',
    'photographer-maternity',
    'furniture-carpenter',
    'chef',
    'singer',
    'event-planner',
    'dj',
    'dance-performer',
    'mc',
    'fashion-advisor',
    'college-entrance-exam-tutor',
    'media-translator',
    'wedding-planner',
    'private-investigator',
    'pastry-chef',
    'stylist',
    'refrigerator-cleaner',
    'bartender',
    'nutritionist',
    'food-coordinator',
    'highschool-entrance-exam-tutor',
    'middle-school-entrance-exam-tutor',
    'computer-virus-support',
    'buddhist-priest-monk',
    'photographer-engagement',
    'photographer-corporate',
    'estate-items-liquidator',
    'second-hand-purchasing',
    'antique-appraisal',
    'pet-aroma',
    'photographer-wedding',
    'cardboard-production',
    'fooddeliveryservice',
    'photographer-family-portrait',
    'marriage-arranger',
    'photographer-753-ceremony',
    'travelagent',
    'photographer-party-event',
    'photographer-coming-of-age',
  ];

  return simpleQuestionRequestType2IdArray.includes(jobType.id);
};

/** Function  */
export const shouldShowLocationSelectFields = (jobTypeId: string, url = '') => {
  if (!url) {
    return LOCATION_SELECT_FIELDS_JOB_TYPE_LIST.includes(jobTypeId);
  }
  return LOCATION_SELECT_FIELDS_JOB_TYPE_LIST.includes(jobTypeId) && isUrlVerticalPage(url);
};

export const getIsZipCodeExampleJobTypes = (jobTypeId: string) => {
  const zipCodeFormEntryArray = [
    'walking-instructor',
    'garage-carport-professional',
    'garbage-disposal',
    'housecleaner',
    'personal-chef',
    'manicurist',
  ];
  return zipCodeFormEntryArray.includes(jobTypeId);
};

/** Function to determine whether to introduce a character. Make the url optional, because in LandingPageDefault, it is not necessary to use the url */
export const shouldShowMikity = (jobTypeId: string, url = '') => {
  if (!url) {
    return INTRODUCING_CHARACTER_JOB_TYPE_LIST.includes(jobTypeId);
  }
  return INTRODUCING_CHARACTER_JOB_TYPE_LIST.includes(jobTypeId) && isUrlVerticalPage(url);
};
