import { useCallback, useState } from 'react';

interface Options {
  shouldStringifyString: boolean;
}
const DEFAULT_OPTIONS: Options = {
  shouldStringifyString: true,
};

function deserialize<T>(value: string): T {
  try {
    return JSON.parse(value) as T;
  } catch {
    // @ts-expect-error JSON.parse will throw if the value is string
    return value as T;
  }
}

function serialize<T>(value: T, shouldStringifyString: boolean): string {
  return typeof value === 'string' && !shouldStringifyString ? value : JSON.stringify(value);
}

function useLocalStorage<T>(key: string, defaultValue: T, options: Partial<Options> = {}) {
  const { shouldStringifyString } = { ...DEFAULT_OPTIONS, ...options };
  const [value, setValue] = useState<T>(() => {
    const item = typeof window === 'undefined' ? '' : localStorage.getItem(key);
    return item ? deserialize(item) : defaultValue;
  });

  const handleChange = useCallback(
    (paramValue: T | ((paramValue: T) => T)) => {
      try {
        // when using the updater function the setting of local storage does not occur until the next
        // render cycle. This caused the authLogic to fail as the values had not been written to local
        // storage
        const newValue = paramValue instanceof Function ? paramValue(value) : paramValue;
        localStorage.setItem(key, serialize(newValue, shouldStringifyString));
        setValue(newValue);
      } catch (_error) {
        // Do nothing for now
      }
    },
    [key, shouldStringifyString, value]
  );

  const clearValue = useCallback(() => {
    setValue(defaultValue);
    localStorage.removeItem(key);
  }, [defaultValue, key]);

  return [value, handleChange, clearValue] as const;
}

export default useLocalStorage;
