import { Context, ContextEnum, OAuth2Providers } from '@z-components/utils/oauth';
import Analytics, { LogOptions } from './analytics';
import { createTelemetryBeacon, sendFunnelEvent } from 'utils/telemetry';

import {
  loadDataLayerVariables,
  trackingEventForRequestCreation,
  trackingEventForProRegistration,
  GA4EventRequestCreation,
  GA4EventProRegistration,
} from '../eventTrackingForGA4';
import { Goal, triggerGoal } from 'utils/convert';

interface MakeSocialAnalyticsOptions {
  context: Context;
  isNewUser?: boolean;
  jobTypeCategories?: Array<string>;
  jobTypeId: string;
  provider: OAuth2Providers;
  route?: 'emailRoute' | 'mobilePhoneRoute';
  testVariant?: string | null;
}

export default class SocialAnalytics {
  context: Context;
  jobTypeId: string;
  provider: OAuth2Providers;
  jobTypeCategories?: Array<string>;
  isNewUser: boolean;
  route?: 'emailRoute' | 'mobilePhoneRoute';
  testVariant?: string | null;

  private static instance: SocialAnalytics;
  private analyticsLogOptions: LogOptions;

  private constructor(options: MakeSocialAnalyticsOptions) {
    this.context = options.context;
    this.jobTypeId = options.jobTypeId;
    this.jobTypeCategories = options.jobTypeCategories;
    this.provider = options.provider;
    this.isNewUser = Boolean(options.isNewUser);
    this.route = options.route;
    this.testVariant = options.testVariant ?? null;
    this.analyticsLogOptions = {
      jobTypeId: this.jobTypeId,
      shouldDedupeEventsInSession: true,
      beacon: createTelemetryBeacon({
        // TODO: When introducing a scenario where there can be more than one provider, we should regenerate
        // the instance
        provider: this.provider,
        context: this.context,
        action: 'shouldOverride',
      }),
    };
  }

  static getInstance(options: MakeSocialAnalyticsOptions) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!SocialAnalytics.instance) {
      loadDataLayerVariables();
      SocialAnalytics.instance = new SocialAnalytics(options);
      return SocialAnalytics.instance;
    }
    return SocialAnalytics.instance;
  }

  private makeOptionOverrides({ shouldDedupeEventsInSession }: LogOptions) {
    const optionOverrides = { ...this.analyticsLogOptions };
    if (shouldDedupeEventsInSession !== undefined) {
      optionOverrides.shouldDedupeEventsInSession = shouldDedupeEventsInSession;
    }
    return optionOverrides;
  }

  /**
   * denotes social flow started from request form
   */
  socialRequestFlowStarted() {
    // If there previously was a social request flow started for this jobTypeId there would be
    // an existing entry, ensure we unset so we can track a new instance of the user going
    // throw the social portion of the request flow.
    Analytics.unsetAnalyticsActionMap(this.jobTypeId);
    Analytics.logGA(
      {
        action: 'socialLoginClicked',
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        label: this.provider,
        customParameters: {
          // eslint-disable-next-line id-length
          C1: this.jobTypeCategories?.[0] ?? '',
        },
      },
      this.analyticsLogOptions
    );
    trackingEventForRequestCreation(GA4EventRequestCreation.loginStep1Passed, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0] ?? '',
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1] ?? '',
      jobTypeId: this.jobTypeId,
      socialType: 'line',
      whereFromForCreataion: this.route,
    });
    triggerGoal(Goal.loginStep1Passed);
    trackingEventForRequestCreation(GA4EventRequestCreation.socialClicked, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0] ?? '',
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1] ?? '',
      jobTypeId: this.jobTypeId,
      socialType: 'line',
      whereFromForCreataion: this.route,
    });
    sendFunnelEvent(this.jobTypeId, 'socialClicked', { testVariant: this.testVariant });
  }
  /**
   * denotes NEW client user was registered
   */
  socialRequestNewUserCreated() {
    Analytics.logGA(
      {
        action: 'socialAccountCreated',
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        label: this.provider,
      },
      this.analyticsLogOptions
    );

    trackingEventForRequestCreation(GA4EventRequestCreation.socialLinkageCreated, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0] ?? '',
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1] ?? '',
      existingUser: false,
      jobTypeId: this.jobTypeId,
      socialType: 'line',
    });
    sendFunnelEvent(this.jobTypeId, 'socialLinkageCreated', { stepMode: 'newUser' });
  }

  /**
   * denotes an EXISTING client was implicitly linked
   */
  socialRequestUserImplicitlyLinked() {
    Analytics.logGA(
      {
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        action: 'socialImplicitLinked',
        label: this.provider,
      },
      this.analyticsLogOptions
    );
    trackingEventForRequestCreation(GA4EventRequestCreation.socialLinkageCreated, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0] ?? '',
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1] ?? '',
      existingUser: true,
      jobTypeId: this.jobTypeId,
      socialType: 'line',
    });
    sendFunnelEvent(this.jobTypeId, 'socialLinkageCreated', { stepMode: 'implicitLink' });
  }

  /**
   * denotes an EXISTING pro explicitly linked their account via email pw
   */
  socialRequestUserExplicitlyLinked() {
    Analytics.logGA(
      {
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        action: 'socialExplicitLink',
        label: this.provider,
      },
      this.analyticsLogOptions
    );
    trackingEventForRequestCreation(GA4EventRequestCreation.socialLinkageCreated, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0] ?? '',
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1] ?? '',
      existingUser: true,
      jobTypeId: this.jobTypeId,
      socialType: 'line',
    });
    sendFunnelEvent(this.jobTypeId, 'socialLinkageCreated', { stepMode: 'explicitLink' });
  }

  /**
   * denotes a user PASSED the email verify step
   */
  socialRequestEmailVerifyPassed() {
    Analytics.logGA(
      {
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        action: 'socialEmailVerifyPassed',
        label: this.provider,
        customParameters: {
          // eslint-disable-next-line id-length
          C1: this.jobTypeCategories?.[0] ?? '',
        },
      },
      this.analyticsLogOptions
    );
    sendFunnelEvent(this.jobTypeId, 'socialEmailConfirmationClicked', {
      testVariant: this.testVariant,
    });
  }

  socialEmailConfirmationClicked() {
    trackingEventForRequestCreation(GA4EventRequestCreation.socialEmailConfirmationClicked, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0] ?? '',
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1] ?? '',
      jobTypeId: this.jobTypeId,
      socialType: 'line',
    });
  }

  socialPhoneNumberViewed() {
    trackingEventForRequestCreation(GA4EventRequestCreation.socialPhoneNumberViewed, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0] ?? '',
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1] ?? '',
      jobTypeId: this.jobTypeId,
      socialType: this.provider,
    });
  }

  /**
   * denotes an authenticated user passed the phone step
   */
  socialRequestPhonePassed() {
    Analytics.logGA(
      {
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        action: 'socialPhoneNumberPassed',
        label: this.provider,
        customParameters: {
          // eslint-disable-next-line id-length
          C1: this.jobTypeCategories?.[0] ?? '',
        },
      },
      this.analyticsLogOptions
    );
    trackingEventForRequestCreation(GA4EventRequestCreation.socialPhoneNumberClicked, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0] ?? '',
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1] ?? '',
      jobTypeId: this.jobTypeId,
      socialType: 'line',
    });
    sendFunnelEvent(this.jobTypeId, 'socialPhoneNumberClicked', { testVariant: this.testVariant });
  }

  /**
   * denotes an authenticated user pressed the submit button which should lead to request submission
   */
  socialSubmitNewRequest() {
    Analytics.logGA(
      {
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        action: 'submitNewRequest',
        label: this.provider,
        customParameters: {
          // eslint-disable-next-line id-length
          C1: this.jobTypeCategories?.[0] ?? '',
        },
      },
      this.analyticsLogOptions
    );
    sendFunnelEvent(this.jobTypeId, 'newRequestSubmitted', {
      stepMode: 'social',
      testVariant: this.testVariant,
    });
  }

  /**
   * denotes a successful request creation originating from a social method
   */
  socialSubmitNewRequestSuccess() {
    Analytics.logGA(
      {
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        action: 'socialSubmitNewRequestSuccess',
        label: this.provider,
        customParameters: {
          // eslint-disable-next-line id-length
          C1: this.jobTypeCategories?.[0] ?? '',
        },
      },
      this.analyticsLogOptions
    );

    trackingEventForRequestCreation(GA4EventRequestCreation.newRequestSubmitted, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0] ?? '',
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1] ?? '',
      existingUser: !this.isNewUser,
      jobTypeId: this.jobTypeId,
      whereFromForCreataion: 'socialPhoneNumber',
    });

    trackingEventForRequestCreation(GA4EventRequestCreation.newRequestCreated, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0] ?? '',
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1] ?? '',
      jobTypeId: this.jobTypeId,
    });
    triggerGoal(Goal.newRequestCreated);
    sendFunnelEvent(this.jobTypeId, 'newRequestCreated', {
      stepMode: 'social',
      testVariant: this.testVariant,
    });
  }

  socialEmailVerifyViewed(isEmailLinkable: boolean) {
    Analytics.logGA(
      {
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        action: 'socialEmailVerifyViewed',
        label: this.provider,
      },
      this.analyticsLogOptions
    );

    if (isEmailLinkable) {
      trackingEventForRequestCreation(GA4EventRequestCreation.socialUserAuthViewed, {
        // eslint-disable-next-line id-length
        C1: this.jobTypeCategories?.[0],
        // eslint-disable-next-line id-length
        C2: this.jobTypeCategories?.[1],
        jobTypeId: this.jobTypeId,
        socialType: this.provider,
      });
    } else {
      trackingEventForRequestCreation(GA4EventRequestCreation.socialEmailConfirmationViewed, {
        // eslint-disable-next-line id-length
        C1: this.jobTypeCategories?.[0] ?? '',
        // eslint-disable-next-line id-length
        C2: this.jobTypeCategories?.[1] ?? '',
        jobTypeId: this.jobTypeId,
        socialType: this.provider,
      });
    }
    sendFunnelEvent(this.jobTypeId, 'socialEmailConfirmationViewed');
  }

  /**
   * Scenario where a user backs out from any part of the social request flow
   */
  socialUserBackedFromFlow() {
    Analytics.logGA(
      {
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        action: 'socialUserBackedFromFlow',
        label: this.provider,
      },
      this.analyticsLogOptions
    );
  }

  /**
   * Records event hits on password reset flow initiated. deduping events by sessions is being skipped
   * because we want to understand how often this is initiated overall.
   */
  socialPasswordResetStarted(options: { shouldDedupeEventsInSession?: boolean } = {}) {
    const analyticsOptions = this.makeOptionOverrides(options);
    Analytics.logGA(
      {
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        action: 'socialPasswordResetStarted',
        label: this.provider,
      },
      analyticsOptions
    );
  }

  socialPasswordResetCompleted() {
    Analytics.logGA(
      {
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        action: 'socialPasswordResetCompleted',
        label: this.provider,
      },
      this.analyticsLogOptions
    );
  }

  socialUserAuthViewed() {
    trackingEventForRequestCreation(GA4EventRequestCreation.socialUserAuthViewed, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0],
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1],
      jobTypeId: this.jobTypeId,
    });
  }

  socialUserAuthClicked() {
    trackingEventForRequestCreation(GA4EventRequestCreation.socialUserAuthClicked, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0],
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1],
      jobTypeId: this.jobTypeId,
    });
  }

  socialUserAuthSuccess() {
    Analytics.logGA(
      {
        category: Analytics.makeRequestCategoryString(this.jobTypeId),
        action: 'socialEmailVerifyPassed',
        label: this.provider,
        customParameters: {
          // eslint-disable-next-line id-length
          C1: this.jobTypeCategories?.[0] ?? '',
        },
      },
      this.analyticsLogOptions
    );
    trackingEventForRequestCreation(GA4EventRequestCreation.socialUserAuthSuccess, {
      // eslint-disable-next-line id-length
      C1: this.jobTypeCategories?.[0],
      // eslint-disable-next-line id-length
      C2: this.jobTypeCategories?.[1],
      jobTypeId: this.jobTypeId,
    });
  }

  // eslint-disable-next-line class-methods-use-this, camelcase
  pro_socialEmailVerifyViewed() {
    if (this.context === ContextEnum.proRegistration) {
      trackingEventForProRegistration(GA4EventProRegistration.pro_socialEmailVerifyViewed, {
        socialType: this.provider,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this, camelcase
  pro_socialEmailVerifyClicked() {
    if (this.context === ContextEnum.proRegistration) {
      trackingEventForProRegistration(GA4EventProRegistration.pro_socialEmailVerifyClicked, {
        socialType: this.provider,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this, camelcase
  pro_socialUserAuthViewed() {
    if (this.context === ContextEnum.proRegistration) {
      trackingEventForProRegistration(GA4EventProRegistration.pro_socialUserAuthViewed, {
        socialType: this.provider,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this, camelcase
  pro_socialUserAuthClicked() {
    if (this.context === ContextEnum.proRegistration) {
      trackingEventForProRegistration(GA4EventProRegistration.pro_socialUserAuthClicked, {
        socialType: this.provider,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this, camelcase
  pro_socialUserAuthSuccess() {
    if (this.context === ContextEnum.proRegistration) {
      trackingEventForProRegistration(GA4EventProRegistration.pro_socialUserAuthSuccess, {
        socialType: this.provider,
      });
    }
  }
}
