import { ButtonHTMLAttributes, FC } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

interface Props {
  className?: string;
  dataGtmClick?: string;
  id?: string;
  isDisabled?: boolean;
  isSubmitting?: boolean;
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  testId?: string;
  text?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

/**
 * Use one of the class on a `<button>` HTML element to give it the correct style
 * or use the `<Button />` component with the necessary props
 */
const Button: FC<Props> = ({
  children,
  className = 'z-btn',
  dataGtmClick,
  id,
  isDisabled = false,
  isSubmitting = false,
  onClick = () => null,
  text,
  type = 'button',
  testId,
}) => (
  <button
    data-test-id={testId}
    className={classNames(
      ...className
        .split(' ')
        // Only apply CSS module for z-btn classes
        .map((cssClass) => (cssClass.startsWith('z-btn') ? styles[cssClass] : cssClass))
    )}
    data-gtm-click={dataGtmClick}
    disabled={isDisabled || isSubmitting}
    onClick={onClick}
    id={id ? id : ''}
    /* eslint-disable react/button-has-type */
    type={type}
  >
    {text ?? children}
  </button>
);

export default Button;
export type { Props as ButtonProps };
